class BuchhandlungInfo extends HTMLElement {
  connectedCallback() {
    this.setElements();
    this.bindEvents();
  }

  setElements() {
    this.buttonKontakt = this.querySelector('.button-kontakt');
    this.buttonOeffnungszeiten = this.querySelector('.button-oeffnungszeiten');
    this.overlayKontakt = this.querySelector('.slidein-01');
    this.overlayOeffnungszeiten = this.querySelector('.slidein-02');
    this.buttonCloseOverlayKontakt = this.querySelector('.close-overlay-kontakt');
    this.buttonCloseOverlayOeffnungszeiten = this.querySelector('.close-overlay-oeffnungszeiten');
  }

  bindEvents() {
    this.buttonKontakt.addEventListener('click', () => this.openOverlayKontakt());
    this.buttonOeffnungszeiten.addEventListener('click', () => this.openOverlayOeffnungszeiten());
    this.buttonCloseOverlayKontakt.addEventListener('click', () => this.closeOverlayKontakt());
    this.buttonCloseOverlayOeffnungszeiten.addEventListener('click', () => this.closeOverlayOeffnungszeiten());
  }

  openOverlayKontakt() {
    this.overlayKontakt.showModal();
  }

  openOverlayOeffnungszeiten() {
    this.overlayOeffnungszeiten.showModal();
  }

  closeOverlayKontakt() {
    this.overlayKontakt.setAttribute('closing','');
    this.overlayKontakt.close();
    this.overlayKontakt.removeAttribute('closing');
  }

  closeOverlayOeffnungszeiten() {
    this.overlayOeffnungszeiten.setAttribute('closing','');
    this.overlayOeffnungszeiten.close();
    this.overlayOeffnungszeiten.removeAttribute('closing');
  }
}

if (!customElements.get("buchhandlung-info")) {
  customElements.define("buchhandlung-info", BuchhandlungInfo);
}
